import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RoundsService {
  updatedRounds = new Subject(); 

  link;
  API;
  endpoint = "match-rounds"; 
  constructor(private http: HttpClient, private router: Router) {
    this.link = environment.link;
    this.API = environment.API;
  }
  getUpdatedRounds() {
    return this.updatedRounds.asObservable();
  }
  Rounds() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/all`)
      .subscribe((result) => {
        this.updatedRounds.next(result);
      });
  }
   
}
