import { Pipe, PipeTransform } from '@angular/core';
import { CharedService } from 'src/app/services/chared.service';

@Pipe({
  name: 'playerNom'
})
export class PlayerNomPipe implements PipeTransform {
constructor(private charedService: CharedService) { }
  transform(nom: string, prenom: string): string {
      const concatenatedName = `${nom?.toUpperCase()} ${this.charedService.capitalizeWords(prenom)}`;
      return concatenatedName;
 
    // return '';
  }
}
