import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    'providedIn': "root"
})
export class CompetitionService {
    updatedCompetitions = new Subject();
    competitions;
    link;
    API;
    endpoint="auth"
    constructor(private http: HttpClient) {
        this.link = environment.link;
        this.API = environment.API;
    }
    getUpdatedCompetitionsListener() {
        return this.updatedCompetitions.asObservable();
    }
    getCompetitions() {
        this.http.get(
            `${this.link}/${this.API}/${this.endpoint}/competitions`).subscribe(
            (result: any) => {
                this.competitions = result;
                this.updatedCompetitions.next([...this.competitions]);
            }
        );
    }
    getAllCompetitions() {
        this.http.get(
            `${this.link}/${this.API}/${this.endpoint}/all-competitions`).subscribe(
            (result: any) => {
                this.competitions = result;
                this.updatedCompetitions.next([...this.competitions]);
            }
        );
    }
    deleteCompetition(id) {
        return this.http.delete(
            `${this.link}/${this.API}/${this.endpoint}/competition/${id}`);
    }
    addCompetition(infos) {
        return this.http.post(
            `${this.link}/${this.API}/${this.endpoint}/competition`, infos);
    }
    updateCompetition(id, infos) {
        return this.http.post(
            `${this.link}/${this.API}/${this.endpoint}/updateCompetition/${id}`, infos);
    }
}