import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { MatchService } from "src/app/services/match.service";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { CompetitionService } from "src/app/services/competition.service";
import { authService } from "src/app/components/auth/service/auth.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Match } from "./Match";
import { TranslateService } from "@ngx-translate/core";
import { I18nServiceService } from "src/app/services/i18n-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ClubJSON } from "src/app/components/stages/interface/Clubs";
import { RoundsService } from "src/app/services/rounds.service";

@Component({
  selector: "app-match-actions",
  templateUrl: "./match-actions.component.html",
  styleUrls: ["./match-actions.component.css"],
})
export class MatchActionsComponent implements OnInit {
  @Input() path: string = "/match/calendar";
  @Input() Type: string = "";
  @Input() idMath: number = 0;
  @Input() actions = { add: true, update: false };
  @Input() competitions: Array<any> = [];
  @Input() _equipes: Array<any> = [];
  @Input() _stads: Array<any> = [];
  @Input() date_debut: string = "";
  @Output() addMatchCalendar = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  @Input() obj_match: Match;
  match: Match = new Match(
    0,
    0,
    "",
    0,
    "",
    moment().toDate(),
    "00",
    "12",
    moment().toDate(),
    "",
    "",
    "",
    "in",
    0,
    "",
    ""
  );
  rounds:Array<number> = [];
  stads: Array<any> = [];
  selectedEquipes: Array<any> = [];
  isLoading = [true, false, true];
  @ViewChild("matchForm") matchForm: NgForm;
  joueurs;
  competitionSub: Subscription;
  equipeSub: Subscription;
  subscriptions = new Subscription();
  equipes;
  user;
  carteFrame: SafeHtml = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private matchService: MatchService,
    private competitionService: CompetitionService,
    private roundsService: RoundsService,
    private authservice: authService,
    private translate: TranslateService,
    private i18nService: I18nServiceService
  ) {}
  ngOnInit() {
   
    if (this.route.snapshot.params.id != undefined)
      this.idMath = this.route.snapshot.params.id;
    this.route.params.subscribe((p) => {
      if (p.id != undefined) {
        this.idMath = p.id;
        this.matchService.getMatch(this.idMath).subscribe(
          (result: any) => {
            this.match = result;
            this.match.adversaire_id = +this.match.adversaire_id;
            this.match.competition = +this.match.competition;
            this.match.stade = +this.match.stade;
            this.actions.add = false;
            this.actions.update = true;
            let date = new Date(result.match_date);
            const day =
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            const month =
              date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            const year = date.getFullYear();
            this.match.date = year + "-" + month + "-" + day;
            this.match.heure = new Date(this.match.date + " " + result.heure);
            this.match.adresse = result.adress;
            this.match.duree = result.dure;

            this.matchService.getStads();
          },
          (err) => {
            if(err.status == 404)
            this.router.navigate(["403"]); 
          }
        );
      } else {
        if (this.Type != "") {
          this.match = this.obj_match;
        }
      }
    });
    this.authservice.getUpdatedUser().subscribe((user: any) => {
      this.user = user;
    });
    this.i18nService
      .getUpdatedLocaleLangListener()
      .subscribe((locale: string) => {
        this.translate.use(locale);
      });

    this.authservice.getUser();
    if (this.Type == "") {
      this.competitionSub = this.competitionService
        .getUpdatedCompetitionsListener()
        .subscribe((result: Array<any>) => {
          this.competitions = result;
          this.isLoading[0] = false;
        });
      this.competitionService.getCompetitions();
    } else {
      this.isLoading[0] = false;
    }
    if (this.Type == "") {
      this.subscriptions.add(
        this.matchService
          .getUpdatedStadsListner()
          .subscribe((result: Array<any>) => {
            this.stads = result;
            this.isLoading[3] = false;
          })
      );
      this.matchService.getStads();
    } else {
      this.stads = this._stads;
      this.isLoading[3] = false;
    }

    if (this.Type == "") {
      this.equipeSub = this.matchService
        .getEquipes()
        .subscribe((result: Array<any>) => {
          this.equipes = result.filter(
            (element) => element.user_id != this.user?.id
          );
          this.selectedEquipes = JSON.parse(JSON.stringify(this.equipes));
          this.isLoading[2] = false;
        });
    } else {
      this.equipes = this._equipes;
      this.selectedEquipes = JSON.parse(JSON.stringify(this._equipes));
      this.isLoading[2] = false;
    }
    this.matchService.getUpdatedEquipe().subscribe((result: Array<any>) => {
      this.clubs = result;
    });
    this.matchService.getEquipe();
    this.roundsService.getUpdatedRounds().subscribe((result: Array<any>) => {
      // this.rounds=Array(result).fill(0).map((x,i)=>i+1);
    })
    this.roundsService.Rounds();
    // this.action('SEARCH_CLUB', { key: 'U20' })
  }
  searchOpponent(value) {
    this.selectedEquipes = this.equipes.filter(
      (element) =>
        element.slug.toLowerCase().includes(value.toLowerCase()) == true
    );
  }
  changeStadSelected(event) {
    if (this.match.stade == 0) {
      this.match.autrestad = "";
      this.carteFrame = "";
    } else {
      this.match.autrestad = "";
      let selectedStad = this.stads.find(
        (element) => element.id == this.match.stade
      );
      this.match.adresse = selectedStad.location;
      this.carteFrame = this.sanitizer.bypassSecurityTrustHtml(
        selectedStad.carte
      );
    }
  }
  onSave() {
    this.isLoading[1] = true;
    const controls = Object.values(this.matchForm.controls);
    for (let control of controls) {
      if (control.invalid) {
        this.isLoading[1] = false;
        return;
      }
    }
    const date = new Date(this.match.date);
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const year = date.getFullYear();
    this.match.date = year + "-" + month + "-" + day;
    if (this.match.stade == 0) this.match.stade = this.match.autrestad;
    this.match.heure = new Date(this.match.heure);
    this.match.heure = new Date(
      this.match.heure.getTime() - this.match.heure.getTimezoneOffset() * 60000
    );
    this.match.heure = this.match.heure.toISOString().split(".")[0];
    this.matchService.saveMatch(this.match).subscribe(
      (result: any) => {
        if (result) {
          this.router.navigate([this.path]);
          this.addToCalendar(result.insertedId);
          this.close();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getPayers(event: any) {
    this.matchService.getUpdatedPlayer().subscribe((result: any) => {
      this.joueurs = result;
    });
    this.matchService.getPlayer(event.value);
  }
  close() {
    this.closeEvent.emit("true");
  }
  addToCalendar(insertedId: number) {
    this.addMatchCalendar.emit(insertedId);
  }
  onUpdate() {
    this.isLoading[1] = true;
    const controls = Object.values(this.matchForm.controls);
    for (let control of controls) {
      if (control.invalid) {
        this.isLoading[1] = false;
        return;
      }
    }
    const date = new Date(this.match.date);
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const year = date.getFullYear();
    this.match.date = year + "-" + month + "-" + day;
    if (this.match.stade == 0) this.match.stade = this.match.autrestad;
    this.match.heure = new Date(this.match.heure);
    this.match.heure = new Date(
      this.match.heure.getTime() - this.match.heure.getTimezoneOffset() * 60000
    );
    this.match.heure = this.match.heure.toISOString().split(".")[0];
    this.matchService.updateMatchCalendar(this.match, this.idMath).subscribe(
      (result: any) => {
        if (result) {
          this.router.navigate([this.path]);
          this.addToCalendar(this.idMath);
          this.close();
        }
      },
      (error) => {
        this.close();
        console.log(error);
      }
    );
  }
  clubs: any[] = [];
  clubKey: string;
  action(TYPE: string, REQUEST: any = null): void {
    switch (TYPE) {
      case "SEARCH_CLUB":
        if (REQUEST.key && REQUEST.key.trim().length > 2)
          this.matchService
            .SearchByClubsKey(REQUEST.key)
            .subscribe((clubs: any[]) => {
              this.clubs = clubs;
            });
        break;

      default:
        break;
    }
  }
}
