export const environment = {
  production: true,
  access_token: "",
  version: "5.0.14.33",
  // pro | frmf
  type: "frmf",
  //link: "http://localhost:3000",
  // link: "https://apiv2.myteambyfrmf.ma",
  // link: 'MY_APP_API_URL', 
  link_appsfrmf: 'https://api.appsfrmf.ma/api/v1',
  link_pro: 'https://pro-api.myteam.solutions',
  // link: 'https://pro-api.myteam.solutions',
  link: 'https://preprod-api.myteam.solutions',
  football_api: "https://api-football-v1.p.rapidapi.com/v2/",
  api_key: "e3bc398c77msh66c5d037d295c81p1997e4jsn7addf66362a4",
  API: "api",
  API2: "api",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  ID_TOKEN: "ID_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN"
};
