import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { authService } from '../../auth/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CharedService } from 'src/app/services/chared.service';

@Component({
  selector: 'app-global-post',
  templateUrl: './global-post.component.html',
  styleUrls: ['./global-post.component.css']
})
export class GlobalPostComponent implements OnInit {


  constructor(private authService$: authService,    
    private dialog: MatDialog,
    private charedService: CharedService) { }
    @ViewChild('groupeBtn', {static: true}) groupeBtn: ElementRef;
  ngOnInit() {
  }
  actions(CASE: string, REQ: any = null) {
    switch (CASE) {
      case 'refuser':
        this.authService$.logout();
        break;
      case 'autoriser':
        this.authService$.autoriser().subscribe(
          () => {
            this.authService$.refreshToken().subscribe(
              () => {
              },
              () => { }
            );
          },
          () => { }
        );
        this.dialog.closeAll()
        break;
        case "details":
          let dialogRef = this.dialog.open(GlobalPostComponent, {
            width: "auto",
            height: "90vh",
            panelClass: "details-dialog",
          });
          // dialogRef.disableClose = true;
          // this.dialog.closeAll()
        break;
case "scroll":  
REQ.scrollIntoView({behavior: 'smooth'});
break;
      default:
        break;
    }
  }

  
}
