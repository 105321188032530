export interface Match{
  id:number;
  adversaire:number | string;
  adversaire_id:number | string;
  competition:number | string;
  otherOpponentName: string;
  date: Date | string;
  minute: string;
  hour: Date | string;
  heure: Date | string;
  duree: string;
  club:string;
  payer:string;
  out_in: string;
  stade:number |  string;
  adresse: string;
  autrestad: string;
  clubId:number;
}

export class Match{
 constructor(  public id:number,
  public adversaire:number | string,
  public adversaire_id:number | string,
  public competition:number | string,
  public otherOpponentName: string,
  public date: Date | string,
  public minute: string,
  public hour: Date | string,
  public heure:  Date | string,
  public duree: string,
  public club:string,
  public payer:string,
  public out_in: string,
  public stade:number |  string,
  public adresse: string,
  public autrestad: string,
  public clubId: number = null
  ){}
}