import { color } from "echarts";

export const ngRoutes = () => {
  return [
    {
      key: "calendar",
      label: "Calendrier",
      short_desc: "Calendrier des Equipes nationales du Maroc",
      navigate: ['/calendrier/frmf'],
      children: [
        // {
        //   key: "calendar-frmf",
        //   label: "Calendrier FRMF",
        //   short_desc: "Calendrier des Equipes nationales du Maroc",
        //   navigate: ['/calendrier/frmf'],
        //   disabled: false
        // },
        // {
        //   key: "calendar-club",
        //   label: "Calendrier CLUB",
        //   short_desc: "Calendrier des Equipes nationales du Maroc",
        //   navigate: ['/calendrier/club'],
        //   disabled: false
        // },
      ]
    },

    {
      key: "datahub-database",
      label: "Base de données Datahub",
      short_desc: "Base de données des joueurs marocains toutes catégories confondues",
      navigate: ['/datahub/database/list-players-post'],
    },
    {
      key: "datahub-ranking",
      label: "Cartes de Profil par Postes.",
      short_desc: "Cartes de Profil des Postes des joueurs marocains",
      navigate: ['/datahub/ranking'],
    },
    {
      key: "scouting",
      label: "Scouting",
      short_desc: "Modules liés a scouting.",
      navigate: [],
      children: [
        {
          key: "scouting-men",
          label: "Scouting men",
          short_desc: "Cartes de Profil des Postes des joueurs marocains",
          navigate: [],
          disabled: true,
          color: '#1f5392',
          children: [
            {
              key: "scouting-ligue",
              label: "Ligue",
              short_desc: "...",
              color: "#061e3bbd",
              navigate: ['/scouting-ligue-m/men/CmRSQLlOC39V2']
            },
            {
              key: "scouting-general",
              label: "General",
              short_desc: "...",
              color: "#061e3bbd",
              navigate: ['/scouting-m/men/A19L5vu8lguQ2']

            }
          ]
        },
        {
          key: "scouting-women",
          label: "Scouting women",
          short_desc: "Cartes de Profil des Postes des joueurs marocains",
          navigate: [],
          disabled: true,
          color: '#6b2866',
          children: [
            {
              key: "scouting-ligue",
              label: "Ligue",
              short_desc: "...",
              color: "#290226b0",
              navigate: ['/scouting-ligue-w/women/CmRSQLlOC39V2']
            },
            {
              key: "scouting-general",
              label: "General",
              short_desc: "...",
              color: "#290226b0",
              navigate: ['/scouting-w/women/A19L5vu8lguQ2']
            }
          ]
        },
      ]
    },
  ];
};
