import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchActionsComponent } from './match-actions.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { DateTimeAdapter, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { MY_CUSTOM_FORMATS } from 'src/app/services/chared.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingModule } from 'src/app/custom-component/loading/loading.module';
import { SharedModule } from '../../shared.module';

export function homeHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/match/', '.json');
}

@NgModule({
  declarations: [MatchActionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
     MatButtonModule,
      MatInputModule,
      MatDatepickerModule,
    MatNativeDateModule,MatSelectModule,
    MatTabsModule,ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    OwlDateTimeModule,LoadingModule,
    OwlNativeDateTimeModule, 
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: homeHttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
  ],
  providers:[
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
  { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ],
  exports:[MatchActionsComponent]
})
export class MatchActionsModule { }
