import { Component, ElementRef, ViewChild } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import { I18nServiceService } from "src/app/services/i18n-service.service";
import { CharedService } from "./services/chared.service";
import { authService } from "./components/auth/service/auth.service";
import { PdfService } from "./services/pdf.service";
import { environment } from "src/environments/environment";
import { User } from "./shared/interface/user.model";
import { MatDialog } from "@angular/material/dialog";
import { ShortGlobalPostComponent } from "./components/account/global-post/dialogs/short-global-post/short-global-post.component";

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  user: User;
  printed = false;
  constructor(
    public charedService: CharedService,
    private dialog: MatDialog,
    private authService: authService,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private i18nService: I18nServiceService
  ) {
    translate.setDefaultLang("fr");
  }
  ngOnInit() {
    this.printed = true;
    this.authService.getUpdatedUser().subscribe((result: User) => {
      if (result?.id) {
        this.user = result;
        this.authService.getPalettes(result?.id).subscribe((palette) => {
          if (palette) {

            Object.keys(palette).forEach((key) => {
              if (!environment.production) {

                if (this.printed) {
                  console.log(
                    `%cUSER ${result?.nom.toUpperCase().trim()}`,
                    `color: ${palette["--secondary-color"] ?? "white"
                    }; background-color: ${palette["--primary-color"] ?? "#5F87C1"
                    }; padding: 3px 6px ; border-radius: 3px;`,
                    result
                  );
                  this.printed = false;
                }

              }

              this.charedService.updatePlatformColor(key, palette[key]);
            });
          }
        });
        if (
          this.user?.type === "sub_user" &&
          this.user?.autoriser == 0 &&
          ![283, 252, 282].includes(this.user.id)
        ) {
          // alert("called open");

          if (!this.authService.opened) {
            this.authService.opened = true;
            this.dialog.open(ShortGlobalPostComponent, {
              width: "auto",
              height: "auto",
              panelClass: "warning-dialog",
              disableClose: true
            });

          }

        }
      }
    });
    this.authService.getUser();


    this.i18nService
      .getUpdatedLocaleLangListener()
      .subscribe((locale: string) => {
        this.translate.use(locale);
      });
    this.i18nService.getLocal();
    this.dateAdapter.setLocale("fr-FR");
  }

  title = "sidenav-with-multilevel-menu";

  isSideNavCollapsed = false;
  screenWidth = 0;

  onToggleSideNav(data: SideNavToggle): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }
}
