import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { SideblocksComponent } from './layout/sideblocks/sideblocks.component';
import { PresidingGuard } from './guards/presiding.guard';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
const commonRouteConfig = {
  canActivate: [PresidingGuard],
};
const routes: Routes = [
  { path: 'login', loadChildren: './components/auth/login/login.module#LoginModule', canActivate: [LoginGuard] },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: './components/presentation/dashboard.module#DashboardModule', ...commonRouteConfig },
  { path: 'datahub', loadChildren: './components/datahub/datahub.module#DatahubModule' },
  { path: 'scouting-m/:sexe/:age', loadChildren: './components/scouting/scouting.module#ScoutingModule' },
  { path: 'scouting-w/:sexe/:age', loadChildren: './components/scouting/scouting.module#ScoutingModule' },
  { path: 'scouting-ligue-m/:sexe/:age', loadChildren: './components/scouting/scouting.module#ScoutingModule' },
  { path: 'scouting-ligue-w/:sexe/:age', loadChildren: './components/scouting/scouting.module#ScoutingModule' },
  { path: 'prevention', loadChildren: './components/prevention/prevention.module#PreventionModule', ...commonRouteConfig },
  { path: 'ctr-reporting', loadChildren: './components/ctr-reporting/ctr-reporting.module#CtrReportingModule', ...commonRouteConfig },
  { path: 'myaccount', loadChildren: './components/account/account.module#AccountModule', ...commonRouteConfig },
  { path: 'effectif', loadChildren: './components/effectif/effectif.module#EffectifModule', ...commonRouteConfig },
  { path: 'mail', loadChildren: './components/mail/mail.module#MailModule', ...commonRouteConfig },
  { path: 'media-center', loadChildren: './components/media-center/media-center.module#MediaCenterModule', ...commonRouteConfig },
  { path: 'playerDetails', loadChildren: './components/effectif/my-team/player-details/modules/player-details.module#PlayerDetailsModule', ...commonRouteConfig },
  { path: 'match', loadChildren: './components/match/match.module#MatchModule', ...commonRouteConfig },
  { path: 'entrainement', loadChildren: './components/entrainement/entrainement.module#EntrainementModule', ...commonRouteConfig },
  { path: 'stats', loadChildren: './components/stats/stats.module#StatsModule', ...commonRouteConfig },
  { path: 'calendrier', loadChildren: './components/calendrier/calendrier.module#CalendrierModule' },
  { path: 'saison', loadChildren: './components/saison/saison.module#SaisonModule', ...commonRouteConfig },
  { path: 'medical', loadChildren: './components/global-medical/global-medical.module#GlobalMedicalModule' },
  { path: 'administration', loadChildren: './components/adminstration/administration.module#AdministrationModule', ...commonRouteConfig },
  { path: 'physique', loadChildren: './components/physique/physique.module#PhysiqueModule', ...commonRouteConfig },
  { path: 'exercise', loadChildren: './components/exercise/exercise.module#ExerciseModule', ...commonRouteConfig },
  { path: 'etude-adversaire', loadChildren: './components/etude-adversaire/etude-adversaire.module#EtudeAdversaireModule', ...commonRouteConfig },
  
  { path: 'logistic-checklist', loadChildren: './components/logistic-checklist/logistic-checklist.module#LogisticChecklistModule', ...commonRouteConfig },
  { path: 'pcma', loadChildren: './components/pcma/pcma.module#PcmaModule', ...commonRouteConfig },
  { path: 'camp', loadChildren: './components/camp/camp.module#CampModule', ...commonRouteConfig },
  { path: 'controlle-panel', loadChildren: './components/controlle-panel/controlle-panel.module#ControllePanelModule', ...commonRouteConfig },
  { path: 'matchs-events', loadChildren: './components/video-analysis/video-analysis.module#VideoAnalysisModule', ...commonRouteConfig },
  { path: 'configuration-panel', loadChildren: './components/configuration-panel/configuration-panel.module#ConfigurationPanelModule', ...commonRouteConfig },
  { path: 'support', loadChildren: './components/support/support.module#SupportModule', ...commonRouteConfig },
  { path: 'performance', loadChildren: './components/performance/performance.module#PerformanceModule' },
  { path: 'orders', loadChildren: './components/orders/orders.module#OrdersModule', ...commonRouteConfig },
  { path: 'athlete', loadChildren: './components/athlete/athlete.module#AthleteModule', ...commonRouteConfig },
  { path: 'staff', loadChildren: './components/staffs/staffs.module#StaffsModule', ...commonRouteConfig },
  { path: 'manage-users', loadChildren: './components/users/users.module#UsersModule', ...commonRouteConfig },
  { path: 'stages', loadChildren: './components/stages/stages.module#StagesModule', ...commonRouteConfig },
  { path: 'calendrier-dtn', loadChildren: './components/calendrier-dtn/calendrier-dtn.module#CalendrierDtnModule', ...commonRouteConfig },
  { path: 'coffre-fort', loadChildren: './components/coffre-fort/coffre-fort.module#CoffreFortModule', ...commonRouteConfig },
  { path: 'data-export', loadChildren: './components/data-export/data-export.module#DataExportModule', ...commonRouteConfig },
  { path: 'side-blocks', component: SideblocksComponent,canActivate: [AuthGuard] },
  { path: '403', component: NotAuthorizedComponent, canActivate: [AuthGuard] },
  { path: 'workload', loadChildren: './components/workload/workload.module#WorkloadModule', ...commonRouteConfig },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
