import { Component, OnInit } from '@angular/core';
import { I18nServiceService } from 'src/app/services/i18n-service.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/components/main-nav/main-nav.component';

@Component({
  selector: 'app-athlete',
  templateUrl: './athlete.component.html',
  styleUrls: ['./athlete.component.css']
})
export class AthleteComponent implements OnInit {
  constructor(private translate: TranslateService, private i18nService: I18nServiceService) { }
  user: User;
  ngOnInit(): void {
    this.i18nService.getUpdatedLocaleLangListener().subscribe((locale: string) => {
      this.translate.use(locale);
    });
    this.i18nService.getLocal();
  }

}
