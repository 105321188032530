import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { authService } from 'src/app/components/auth/service/auth.service';
import { CharedService } from 'src/app/services/chared.service';
import { GlobalPostComponent } from '../../global-post.component';

@Component({
  selector: 'app-short-global-post',
  templateUrl: './short-global-post.component.html',
  styleUrls: ['./short-global-post.component.css']
})
export class ShortGlobalPostComponent implements OnInit {
  constructor(private authService$: authService,    
    private dialog: MatDialog,
    private charedService: CharedService) { }
  ngOnInit() {
  }
  actions(CASE: string, REQ: any = null) {
    switch (CASE) {
      case 'refuser':
        this.authService$.logout();
        break;
      case 'autoriser':
        this.authService$.autoriser().subscribe(
          () => {
            this.authService$.refreshToken().subscribe(
              () => {
              },
              () => { }
            );
          },
          () => { }
        );
        this.dialog.closeAll()
        break;
        case "details":
          // let dialogRef = this.dialog.open(GlobalPostComponent, {
          //   width: "auto",
          //   height: "90vh",
          //   panelClass: "details-dialog",
          // });
          // dialogRef.disableClose = true;
          // this.dialog.closeAll()
          this.openDialog()
          break;

      default:
        break;
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(GlobalPostComponent, {
      width: 'auto',
      height: "90vh",
      // disableClose:true,
      minHeight:"90vh",
      panelClass: "details-dialog",
      // hasBackdrop: false,
      autoFocus: false,
    });
  }
}
