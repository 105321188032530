import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectCompetitionsComponent } from "../../../../components/account/account-clubs/account-clubs.component";
import {
  Component,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  forwardRef,
  OnInit,
} from "@angular/core";

@Component({
  selector: "mat-select-competitions",
  templateUrl: "./mat-select-competitions.html",
  styleUrls: ["./mat-select-competitions.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatSelectCompetitionsComponentComponent),
      multi: true,
    },
  ],
})
export class MatSelectCompetitionsComponentComponent
  implements ControlValueAccessor, OnInit
{
  @Input() appearance: any;
  @Input() required: true;
  @Input() competitions: any;
  @Input() filter: boolean = true;
  @Input() competition: any;
  @Output() Selectedcompetition: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    // Additional initialization logic if needed
  }
  onChangeDoneComp($event) {
    this.Selectedcompetition.emit(this.competition);
  }

  writeValue(value: any): void {
    this.competition = value;
    // console.log("vv");
    
 
  }
  registerOnChange(fn: any): void {
    // this.onChange = fn;
 
  }

  registerOnTouched(fn: any): void {
    // this.onTouched = fn;
 
  }
  setDisabledState?(isDisabled: boolean): void {
  
  }
}
