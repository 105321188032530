import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectCompetitionsComponentComponent } from "./components/Fields/select-competitions/mat-select-competitions.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { SnackbarComponent } from "./components/snackbar/snackbar.component";
import { DateAgoPipe } from "./pipes/date-ago/date-ago.pipe";
import { SafePipe } from "./pipes/safe-pipe/safe.pipe";
import { ReplacePipe } from "./pipes/replace/replace.pipe";
import { PlayerNomPipe } from "./pipes/player-nom/player-nom.pipe";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LoadingModule } from '../custom-component/loading/loading.module';
import { MatchActionsModule } from './components/match-actions/match-actions.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http'; 
// import { DialogOneExerciceComponent } from './components/dialog-one-exercice/dialog-one-exercice.component';

@NgModule({
  imports: [CommonModule, MatSnackBarModule,
    FormsModule,
    MatFormFieldModule, MatButtonModule, MatInputModule, MatDatepickerModule,
    MatNativeDateModule, MatSelectModule,
    MatTabsModule, MatRadioModule,
    MatProgressSpinnerModule,
    OwlDateTimeModule, MatchActionsModule,
    OwlNativeDateTimeModule, LoadingModule,
  ],
  declarations: [
    MatSelectCompetitionsComponentComponent,
    SnackbarComponent,
    UploadFileComponent,
    DateAgoPipe,
    SafePipe,
    ReplacePipe,
    PlayerNomPipe
  ],
  exports: [
    UploadFileComponent,
    DateAgoPipe,
    SafePipe,
    PlayerNomPipe,
    MatSelectCompetitionsComponentComponent,
    
  ],
})
export class SharedModule {}
