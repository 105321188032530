import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventChoiceComponent } from 'src/app/components/calendrier/dialogs/event-choice/event-choice.component';

@Component({
  selector: 'app-note-tasks',
  templateUrl: './note-tasks.component.html',
  styleUrls: ['./note-tasks.component.css'],
  host: {
    class: "app-note-tasks",
  },
})
export class NoteTasksComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private dialogRef: MatDialogRef<EventChoiceComponent>) { }

  ngOnInit(): void {
  }

}
