import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { authService } from 'src/app/components/auth/service/auth.service';
import { User } from '../shared/interface/user.model';
@Injectable({
  providedIn: 'root'
})
export class PresidingGuard implements CanActivate {
  ROLE: string = null;
  constructor(private authService: authService, private router: Router) {
    authService.getUpdatedUser()
      .subscribe(
        (user: User) => {
          this.ROLE = user?.role;
        }
      );
    authService.getUser();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // this.authService.isNewsVersion();
    
    if (this.ROLE === 'PRESIDING') {
      this.router.navigate(['/side-blocks']);
    }
    return true;
  }

}