import { Component, Input } from '@angular/core';
import { authService } from 'src/app/components/auth/service/auth.service';
import { User } from 'src/app/components/main-nav/main-nav.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loading',
  template: `<div class="loading_"
  [ngStyle]="{'height': height == null ? '93.8vh' : height ,'background':(src != null ?  'linear-gradient(-165deg, var(--primary-linear-gradient-one-color), var(--primary-linear-gradient-two-color))' : 'linear-gradient(-165deg, var(--primary-linear-gradient-one-color), var(--primary-linear-gradient-two-color))' )}">
  <div
    class="d-flex justify-content-start align-items-center image__myteam"
  >
  <ng-container ng-if ></ng-container>
  <ng-container [ngSwitch]="src">
  <ng-container *ngSwitchCase="!null">
  <img [src]="src" width="100%" alt="" />
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="envirement === 'pro' " >    
          <svg version="1.0" style="transform: translateY(-14px);" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                width="200px" height="100px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 119 41" enable-background="new 0 0 119 41" xml:space="preserve">
                <g>
                  <path fill="var(--primary-myteam-color )" d="M43.8,11.8c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1
               c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6v3.3h6.7c0.4,0,0.8,0.2,1.1,0.5
               c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1c-0.3,0.3-0.6,0.4-1.1,0.4h-6.7v5.5c0,0.7,0.1,1.4,0.4,2c0.3,0.6,0.6,1.2,1.1,1.6
               c0.5,0.5,1,0.8,1.7,1.1c0.6,0.3,1.3,0.4,2.1,0.4c0.6,0,1.3-0.1,1.8-0.3c0.6-0.2,1.1-0.5,1.6-0.9c0.4-0.3,0.7-0.5,1.1-0.5
               c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.4C58,28,58,28.2,58,28.4c0,0.2,0,0.5-0.1,0.7
               c-0.1,0.2-0.2,0.4-0.4,0.6c-0.7,0.7-1.5,1.2-2.4,1.5c-0.9,0.3-1.9,0.5-2.9,0.5c-1.2,0-2.2-0.2-3.2-0.6c-1-0.4-1.9-1-2.6-1.7
               c-0.8-0.7-1.3-1.6-1.8-2.6c-0.4-1-0.7-2.1-0.7-3.2V11.8z" />
                  <path fill="var(--primary-myteam-color )" d="M62.4,24.8c0.1,0.6,0.3,1.1,0.6,1.6c0.3,0.5,0.7,0.9,1.1,1.3c0.4,0.4,0.9,0.6,1.5,0.8
               c0.5,0.2,1.1,0.3,1.7,0.3c0.6,0,1.1-0.1,1.5-0.2c0.4-0.1,0.8-0.3,1.2-0.5c0.3-0.2,0.6-0.3,0.9-0.3c0.2,0,0.4,0,0.6,0.1
               c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.5-1.1,0.7
               c-0.5,0.2-1,0.3-1.6,0.4c-0.6,0.1-1.2,0.2-1.9,0.2c-1.1,0-2.2-0.2-3.2-0.7c-1-0.4-1.9-1-2.7-1.8c-0.8-0.7-1.3-1.6-1.8-2.6
               c-0.4-1-0.7-2.1-0.7-3.2c0-1.2,0.2-2.2,0.7-3.2c0.4-1,1-1.9,1.8-2.6c0.8-0.7,1.6-1.3,2.7-1.8c1-0.4,2.1-0.7,3.2-0.7
               c1.1,0,2.2,0.2,3.2,0.6c1,0.4,1.9,1,2.6,1.8c0.8,0.7,1.4,1.6,1.8,2.6c0.4,1,0.7,2.1,0.7,3.2c0,0.4-0.1,0.7-0.4,1
               c-0.3,0.3-0.6,0.4-1.1,0.4H62.4z M72.6,22.3c-0.1-0.6-0.3-1.2-0.6-1.7c-0.3-0.5-0.7-1-1.2-1.4c-0.5-0.4-1-0.7-1.6-0.9
               c-0.6-0.2-1.2-0.3-1.9-0.3c-0.7,0-1.3,0.1-1.9,0.3c-0.6,0.2-1.1,0.5-1.5,0.9c-0.5,0.4-0.8,0.8-1.1,1.4c-0.3,0.5-0.5,1.1-0.6,1.7
               H72.6z" />
                  <path fill="var(--primary-myteam-color )" d="M96.9,21v-0.2c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.5,1.1-0.9,1.8-1.2
               c0.7-0.3,1.4-0.4,2.2-0.4c0.8,0,1.6,0.2,2.3,0.5c0.7,0.3,1.3,0.8,1.8,1.3c0.5-0.6,1.1-1,1.8-1.3c0.7-0.3,1.5-0.5,2.3-0.5
               c0.8,0,1.5,0.1,2.2,0.4c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.2,1.8c0.3,0.7,0.5,1.4,0.5,2.2v9.3c0,0.4-0.1,0.8-0.4,1.1
               c-0.3,0.3-0.6,0.4-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1v-9.1c0-0.4-0.1-0.7-0.2-1s-0.3-0.6-0.6-0.8
               c-0.2-0.2-0.5-0.4-0.9-0.6c-0.3-0.1-0.7-0.2-1-0.2c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.6,0.8
               c-0.1,0.3-0.2,0.6-0.2,0.9c0,0,0,0.1,0,0.2v3.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.5,0.3
               c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1v-3.4c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.6-0.9
               c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.1-0.7-0.2-1-0.2c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.4-0.6,0.7
               c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1l-0.1,9.3c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1,0.4
               c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1V21z" />
                  <path fill="var(--secondary-myteam-color )" d="M20.5,16.8c-0.5-0.5-1.1-0.9-1.8-1.2c-0.7-0.3-1.4-0.4-2.2-0.4c-0.8,0-1.6,0.2-2.3,0.5
               C13.6,16,13,16.4,12.4,17c-0.5-0.6-1.1-1-1.8-1.3c-0.7-0.3-1.5-0.5-2.3-0.5c-0.8,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.3,0.7-1.8,1.2
               c-0.5,0.5-0.9,1.1-1.2,1.8c-0.3,0.7-0.4,1.4-0.4,2.2V21v9.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.7-0.1,1-0.4
               c0.3-0.3,0.4-0.6,0.4-1.1l0.1-9.3c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.3-0.5,0.6-0.7
               c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.4,0.5,0.6,0.9
               c0.1,0.3,0.2,0.7,0.2,1v3.4c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.4,1.1,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.5-0.3
               c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6v-3.5c0-0.1,0-0.2,0-0.2c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.6,0.6-0.8
               c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.8
               c0.1,0.3,0.2,0.7,0.2,1v9.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1v-9.3
               c0-0.8-0.2-1.5-0.5-2.2C21.5,17.9,21,17.3,20.5,16.8z" />
                  <path fill="var(--secondary-myteam-color )"
                    d="M39.4,15.2c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.5,1.1v6.9c0,0.7-0.1,1.4-0.4,2
               c-0.3,0.6-0.6,1.1-1.1,1.6c-0.4,0.5-1,0.8-1.6,1.1c-0.6,0.3-1.2,0.4-1.9,0.4c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.6-1.6-1.1
               c-0.5-0.4-0.8-1-1.1-1.6c-0.3-0.6-0.4-1.3-0.4-2v-7c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.3-0.2-0.5-0.3
               c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1v7c0,1,0.2,1.9,0.5,2.8c0.3,0.9,0.8,1.7,1.4,2.4
               c0.6,0.7,1.3,1.3,2,1.8c0.8,0.5,1.6,0.8,2.6,1v4.3c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.4,1.1,0.4c0.2,0,0.4,0,0.6-0.1
               c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6v-4.3c0.9-0.2,1.8-0.5,2.6-1c0.8-0.5,1.5-1.1,2.1-1.8
               c0.6-0.7,1-1.5,1.4-2.4c0.3-0.9,0.5-1.8,0.5-2.8v-7c0-0.4-0.1-0.8-0.4-1.1C40.2,15.3,39.9,15.2,39.4,15.2z" />
                </g>
                <g>
                  <path fill="var(--secondary-myteam-color )"
                    d="M78.1,37.1v2.2h-1.3v-6.2h3.9c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.4,0.3,0.7
               s0.1,0.5,0.1,0.8c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.7,0.1H78.1z
                M81.1,35.4v-0.4c0-0.1,0-0.2-0.1-0.3s-0.2-0.1-0.3-0.1h-2.6v1.3h2.6c0.1,0,0.2,0,0.3-0.1C81,35.6,81.1,35.5,81.1,35.4z" />
                  <path fill="var(--secondary-myteam-color )" d="M87,37.1h-2v2.2h-1.3v-6.2h4.3c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
               s0.1,0.5,0.1,0.8c0,0.4-0.1,0.8-0.3,1.1s-0.5,0.5-0.9,0.7l1.2,2.3h-1.5L87,37.1z M88.3,35.4v-0.4c0-0.1,0-0.2-0.1-0.3
               s-0.2-0.1-0.3-0.1h-3v1.3h3c0.1,0,0.2,0,0.3-0.1S88.3,35.5,88.3,35.4z" />
                  <path fill="var(--secondary-myteam-color )" d="M96.7,34.9v2.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.3,0.3-0.6,0.4
               c-0.2,0.1-0.4,0.1-0.7,0.1h-2.4c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.3-0.4-0.6c-0.1-0.2-0.1-0.4-0.1-0.7
               v-2.6c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1H95c0.2,0,0.5,0,0.7,0.1
               c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.3,0.4,0.6C96.7,34.5,96.7,34.7,96.7,34.9z M95.4,37.6v-2.6c0-0.1,0-0.2-0.1-0.3
               c-0.1-0.1-0.2-0.1-0.3-0.1h-2.4c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v2.6c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1
               H95c0.1,0,0.2,0,0.3-0.1C95.4,37.8,95.4,37.7,95.4,37.6z" />
                </g>
                <path fill="var(--secondary-myteam-color )" d="M101,7c-0.2-0.1-0.2-0.4-0.5-0.4c-0.3,0-1-0.2-1.6,0.2c-0.6,0.4-0.9,0.4-1.7,0.5c-0.9,0.1-3.2,0.1-3.7,0.4
             c0,0-0.4-0.1-0.6-0.2c-0.3-0.1-1-0.2-1.3-0.5c-0.3-0.3-1.3-1.2-1.8-1.4c-0.6-0.2-0.8-0.2-0.9-0.3c-0.1,0-0.2-0.1-0.2-0.1
             s-0.1-0.6,0.2-0.9C89,4.2,89.1,4,89.2,3.8c0.1-0.2,0.4-1.1,0.2-1.5c-0.1-0.4-0.4-1.1-1.1-1.2c-0.8-0.1-1-0.2-1.6,0.4
             c-0.6,0.5-0.5,0.9-0.4,1.3c0,0,0,0.2,0,0.4c-0.1,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3,0,0.5c0,0.2,0,0.6,0,0.6S86,4.6,86,4.7
             c0,0-1-0.4-1.6-0.2c-0.6,0.2-1,0.2-1.6,0.2c-0.6,0-0.9,0.4-1.7,0.5c-0.7,0.1-0.8,0.5-1,0.4c-0.2-0.1,0,0.1,0.1,0.3
             c0.1,0.2,0.1,0.4,0.1,0.4s-1.2,0-2.3,0.4C77,7.2,76.2,7.4,75,7.4c-1.3,0-1.1,0.1-1.3,0.2c-0.2,0.1-1.1,0.2-1,0.3
             c0.1,0.2,0.3,0.2,0.3,0.2s-0.3,0.2-0.2,0.3c0.1,0.1,0.6-0.2,0.9-0.3c0.3,0-0.1,0.1,0,0.3c0.1,0.2,0.3,0,0.7,0c0.4,0,0.9-0.1,1.3-0.3
             c0.4-0.2,2-0.3,2.9-0.3c0.9,0.1,1.9-0.2,2.3-0.5c0,0,0.2,0.2,0.5,0.1c0,0,1.2-0.5,1.6-0.4c0.4,0.1,0.8,0,0.8,0s0.1,0.5,0.1,0.8
             c0,0.4-0.2,0.7-0.2,1.1c0.1,0.4-0.3,0.8-0.4,1.4c-0.1,0.7-0.8,1.8-1.2,2.6c-0.4,0.7-0.2,0.8-0.4,1.7c-0.1,0.2-0.1,0.5-0.2,0.9
             c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.4,0.4-0.7,0.6c-0.7,0.7-1.2,1.5-1.6,2.4c0,0.1-0.1,0.2-0.1,0.3c-0.4,1-0.6,2.1-0.6,3.2
             c0,1.1,0.2,2.2,0.6,3.2c0.4,1,1,1.9,1.8,2.6c0.7,0.7,1.6,1.3,2.6,1.8c1,0.4,2.1,0.7,3.2,0.7c1,0,1.9-0.2,2.8-0.5
             c0.9-0.3,1.7-0.8,2.4-1.4l0,0.2c0,0.4,0.1,1.3,0.4,1.6c0.3,0.3,0.6,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.4-1.2,0.4-1.6v-6.7
             c0.1-2.9,0.6-5.6-0.5-6.8l-3.3,3.2c0.8,0.9,1.3,2.2,1.3,3.5c0,3-2.4,5.4-5.4,5.4c-3,0-5.4-2.4-5.4-5.4c0-1,0.3-1.9,0.7-2.6
             c0.9-1.7,2.7-2.8,4.8-2.8c1.1,0,2.2,0.3,3,0.9l3.6-2.9l-5.4-0.7c0.1-0.2,0.3-0.4,0.3-0.7c0-0.4-0.1-0.1-0.1-0.7
             c0-0.6,0.2-0.8,0.5-1.6c0.3-0.7,0.6-0.7,1.1-1.6c0.5-0.8,0.1-0.9,1.1-0.7c1,0.3,1.7,0.5,1.9,0.3c0.2-0.2,0.3-0.4,0.3-0.4
             s1,0.1,1.4,0.1c0.4,0,0.7-0.1,1.6-0.3c0.9-0.3,2.2-0.8,2.7-0.8c0.5,0,0.9,0,1.2-0.2c0.2-0.2,0.4-0.3,0.5-0.3c0.1,0,0.4,0.1,0.8,0
             c0.4-0.1,0.3-0.1,0.3-0.3C101.1,7.2,101.2,7.2,101,7z" />
                <image overflow="visible" width="640" height="427" [attr.xlink:href]="user?.logo"
                  transform="matrix(3.750000e-02 0 0 3.750000e-02 74 14.4184)">
                </image>
              </svg>
    </ng-container>
    <ng-container *ngIf="envirement === 'frmf'" >
      
     <div class="img__frmf" >
     <img [src]="src != null ? src  : 'assets/images/scoutingBackgroundWhite.png' " width="100%" alt="" />
   </div>

    </ng-container>
  </ng-container>
  </ng-container>
    <span class="one">.</span><span class="two">.</span
    ><span class="three">.</span>
  </div>
  <div class="textLeading mt-4">
    <span class="mt-2 mb-2">{{ title == null ? textDefault : title }}</span>
    <span>{{ description == null ? '' : description }}</span>
  </div>
</div>`,
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  @Input() src = null;
  @Input() height = null;
  @Input() title = null;
  user: User;
  @Input() description = null;
  envirement: string = 'pro';
  textDefault = "Veuillez patienter pendant le chargement de la page..";
  constructor(private authService: authService) {
    this.envirement = environment.type
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.authService.getUpdatedUser()
      .subscribe(
        (result: User) => {
          this.user = result;
        })
    this.authService.getUser();
  }
}
