import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, observable, of } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ConvoquerPlayer } from "src/app/components/match/match-actions/composition/models/Convoquer";
@Injectable({
  providedIn: "root",
})
export class MatchService {
  doneMatchs;
  updatedDoneMatchs = new Subject();
  newMatchs;
  equipes;
  payers;
  stads;
  updatedNewMatchs = new Subject();
  butMarque;
  updatedButMarque = new Subject();
  butRecu;
  updatedButRecu = new Subject();
  cartons;
  updatedCartons = new Subject();
  remplacements;
  updatedRemplacements = new Subject();
  arrets;
  updatedArrets = new Subject();
  faits;
  updatedFaits = new Subject();
  players;
  updatedPlayers = new Subject();
  updatedAllPlayers = new Subject();
  documents;
  updatedDocuments = new Subject();
  strikerTimeButs;
  updatedStrikerTimeButs = new Subject();
  receiverTimeButs;
  updatedReceiverTimeButs = new Subject();
  updatedButMarqueZone = new Subject();
  updatedCartonJauneZone = new Subject();
  updatedStads = new Subject();
  updatedCartonRougeZone = new Subject();
  updatedButRecuZone = new Subject();
  updatedButMarqueBody = new Subject();
  updatedButRecuBody = new Subject();
  updatedButMarqueType = new Subject();
  updatedButRecuType = new Subject();
  updatedButBothSystem = new Subject();
  updatedButByTerrain = new Subject();
  updatedButByConditionMeteo = new Subject();
  updatedMatchJoueResults = new Subject();
  updatedEquipe = new Subject();
  updatedPlayer = new Subject();
  updatedMatchAvertissement = new Subject();
  updatedPlayersPasses = new Subject();
  updatedPlayersTiming = new Subject();
  updatedPlayersTimingAll = new Subject();
  updatedMatchsTimingAll = new Subject();
  updatedMatchsTiming = new Subject();
  updatedMatchs = new Subject();
  updatedMatchsActions = new Subject();
  updatedAnalyseAdversaires = new Subject();
  updatedAnalyseAdversairesActions = new Subject();
  updatedPlayerDailyChargeW = new Subject();

  link;
  API;
  endpoint = "match";
  footballApiLink;
  footballApiKey;
  constructor(private http: HttpClient, private router: Router) {
    this.link = environment.link;
    this.API = environment.API;
    this.footballApiLink = environment.football_api;
    this.footballApiKey = environment.api_key;
  }
  getUpdatedDoneMatchs() {
    return this.updatedDoneMatchs.asObservable();
  }
  getUpdatedAnalyseAdversairesListner() {
    return this.updatedAnalyseAdversaires.asObservable();
  }
  getUpdatedAnalyseAdversairesActionsListner() {
    return this.updatedAnalyseAdversairesActions.asObservable();
  }
  getUpdatedNewMatchs() {
    return this.updatedNewMatchs.asObservable();
  }
  getUpdatedMatchs() {
    return this.updatedMatchs.asObservable();
  }
  getUpdatedButMarque() {
    return this.updatedButMarque.asObservable();
  }
  getUpdatedButRecu() {
    return this.updatedButRecu.asObservable();
  }
  getUpdatedArrets() {
    return this.updatedArrets.asObservable();
  }
  getUpdatedRemplacement() {
    return this.updatedRemplacements.asObservable();
  }
  getUpdatedFait() {
    return this.updatedFaits.asObservable();
  }
  getUpdatedEquipe() {
    return this.updatedEquipe.asObservable();
  }
  getUpdatedPlayer() {
    return this.updatedPlayer.asObservable();
  }

  getUpdatedCarton() {
    return this.updatedCartons.asObservable();
  }
  getUpdatedPlayers() {
    return this.updatedPlayers.asObservable();
  }
  getUpdatedAllPlayers() {
    return this.updatedAllPlayers.asObservable();
  }
  getUpdatedStrikerTimeButs() {
    return this.updatedStrikerTimeButs.asObservable();
  }
  getUpdatedReceiverTimeButs() {
    return this.updatedReceiverTimeButs.asObservable();
  }
  getUpdatedButMarqueZone() {
    return this.updatedButMarqueZone.asObservable();
  }
  getUpdatedCartonRougeZone() {
    return this.updatedCartonRougeZone.asObservable();
  }
  getUpdatedCartonJauneZone() {
    return this.updatedCartonJauneZone.asObservable();
  }
  getUpdatedButRecuZone() {
    return this.updatedButRecuZone.asObservable();
  }
  getUpdatedButMarqueBody() {
    return this.updatedButMarqueBody.asObservable();
  }
  getUpdatedButRecuBody() {
    return this.updatedButRecuBody.asObservable();
  }
  getUpdatedStadsListner() {
    return this.updatedStads.asObservable();
  }
  getUpdatedButMarqueType() {
    return this.updatedButMarqueType.asObservable();
  }
  getUpdatedButRecuType() {
    return this.updatedButRecuType.asObservable();
  }
  getUpdatedButBothSystem() {
    return this.updatedButBothSystem.asObservable();
  }
  getUpdatedButByTerrain() {
    return this.updatedButByTerrain.asObservable();
  }
  getUpdatedButByConditionMeteo() {
    return this.updatedButByConditionMeteo.asObservable();
  }
  getUpdatedMatchJoueResults() {
    return this.updatedMatchJoueResults.asObservable();
  }
  getUpdatedMatchAvertissement() {
    return this.updatedMatchAvertissement.asObservable();
  }
  getUpdatedPlayersPasses() {
    return this.updatedPlayersPasses.asObservable();
  }
  getUpdatedPlayersTiming() {
    return this.updatedPlayersTiming.asObservable();
  }
  getUpdatedPlayersTiminAll() {
    return this.updatedPlayersTimingAll.asObservable();
  }
  getUpdatedMatchsTiming() {
    return this.updatedMatchsTiming.asObservable();
  }
  getUpdatedMatchsTimingAll() {
    return this.updatedMatchsTimingAll.asObservable();
  }
  getUpdatedMatchsActions() {
    return this.updatedMatchsActions.asObservable();
  }

    getUpdatedPlayerDailyChargeW() {
    return this.updatedPlayerDailyChargeW.asObservable();
  }
  
  getPlayer(id) {
    this.http
      .get(`${this.link}/${this.API}/auth/equipeplayers/${id}`)
      .subscribe((result) => {
        this.payers = result;
        this.updatedPlayer.next(result);
      });
  }
  getEquipe() {
    this.http
      .get(`${this.link}/${this.API}/auth/equipes/all`)
      .subscribe((result) => {
        this.equipes = result;
        this.updatedEquipe.next(result);
      });
  }
  getDoneMatchs(competitionType, limit: number = 10, page: number = 0) {
    let url = `${this.link}/${this.API}/${this.endpoint}/done`;
    if (competitionType !== "" && competitionType !== null) {
      url = url + "/" + competitionType;
    }
    this.http
      .get(url + `?limit=${limit}&page=${page}`)
      .subscribe((result: any) => {
        this.doneMatchs = result;
        this.updatedDoneMatchs.next(result);
      });
  }
  getDoneMatchsState(competitionType) {
    let url = `${this.link}/${this.API}/${this.endpoint}/done-state`;

    if (competitionType !== "" && competitionType !== null) {
      url = url + "/" + competitionType;
    }
    this.http.get(url).subscribe((result: any) => {
      this.doneMatchs = result;
      this.updatedDoneMatchs.next(result);
    });
  }
  getMatchs(competitionType) {
    let url = `${this.link}/${this.API}/${this.endpoint}/all`;
    if (competitionType !== "" && competitionType !== null) {
      url = url + "/" + competitionType;
    }
    this.http.get(url).subscribe((result: any) => {
      this.updatedMatchs.next(result);
    });
  }
  getMatchPlayer() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchsPlayer`
    );
  }
  getNewMatchs(competitionType, limit: number = 10, page: number = 0) {
    let url = `${this.link}/${this.API}/${this.endpoint}/new`;
    if (competitionType !== "" && competitionType !== null) {
      url = url + "/" + competitionType;
    }
    this.http
      .get(url + `?limit=${limit}&page=${page}`)
      .subscribe((result: any) => {
        this.newMatchs = result;
        this.updatedNewMatchs.next(result);
      });
  }
  getNewMatchsState(competitionType) {
    let url = `${this.link}/${this.API}/${this.endpoint}/new-state`;
    if (competitionType !== "" && competitionType !== null) {
      url = url + "/" + competitionType;
    }
    this.http.get(url).subscribe((result: any) => {
      this.newMatchs = result;
      this.updatedNewMatchs.next(result);
    });
  }
  deleteMatch(id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/delete/${id}`
    );
  }
  deleteStatistiqueMatch(id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/deleteStatistiqueMatch/${id}`
    );
  }
  matchActions(id) {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/match_actions/${id}`)
      .subscribe((result: any) => {
        this.updatedMatchsActions.next(result);
      });
  }
  analyseAdversairesActions(id) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/analyseAdversairesActions/${id}`
      )
      .subscribe((result: any) => {
        this.updatedAnalyseAdversairesActions.next(result);
      });
  }
  addAnalyseAdversairesActions(info, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/addAnalyseAdversairesActions/${id}`,
      info
    );
  }
  updateAnalyseAdversairesActions(info, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/updateAnalyseAdversairesActions/${id}`,
      info
    );
  }
  deleteAnalyseAdversairesActions(id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/deleteAnalyseAdversairesActions/${id}`
    );
  }
  deleteMatchActions(id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/match_actions/${id}`
    );
  }
  uploadFile(file) {
    let data = new FormData();
    data.append("file", file);
    const req = new HttpRequest(
      "POST",
      "https://cnk.agency/myteamFilesApi/api/upload",
      data,
      {
        reportProgress: true,
        responseType: "json",
      }
    );

    return this.http.request(req);
  }

  UploadBn(info, id, file) {
    // const eventSource = new EventSource(
    //   `${this.link}/${this.API}/upload-video/upload/${id}`
    // );
    let data = new FormData();
    data.append("file", file);
    data.append("info", info);
    return this.http.post(
      `${this.link}/${this.API}/upload-video/upload/${id}`,
      data,

      {
        reportProgress: true,
        responseType: "json",
        observe: "events",
      }
    );
  }
  addMatchActions(info, id, file = null) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/match_actions/${id}`,
      info
    );
  }

  addMatchActionCanvas(canvas, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/match_actions_canvas/${id}`,
      { canvas: canvas }
    );
  }
  addAnalyseAdversaireActionCanvas(canvas, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/addAnalyseAdversaireActionCanvas/${id}`,
      { canvas: canvas }
    );
  }
  updateMatchActions(info, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/update_match_actions/${id}`,
      info
    );
  }
  getUpdatedDocumentsListner() {
    return this.updatedDocuments.asObservable();
  }
  getDocuments(id, type) {
    this.http
      .get(`${this.link}/${this.API}/joueur/document/${id}/${type}`)
      .subscribe((result) => {
        this.documents = result;
        this.updatedDocuments.next(result);
      });
  }
  deleteDocument(id) {
    return this.http.delete(`${this.link}/${this.API}/joueur/document/${id}`);
  }
  addDocument(info, id, type, file) {
    let data = new FormData();
    data.append("name", info.name);
    data.append("comment", info.comment);
    data.append("date", info.date);
    data.append("type", type);
    data.append("file", file);
    return this.http.post(
      `${this.link}/${this.API}/joueur/document/${id}`,
      data
    );
  }
  updateDocument(info, id) {
    let data = {
      name: info.name,
      comment: info.comment,
      date: info.date,
    };
    return this.http.post(
      `${this.link}/${this.API}/joueur/document-edit/${id}`,
      data
    );
  }
  getStads() {
    this.http.get(`${this.link}/${this.API}/stad/allStads`).subscribe(
      (result) => {
        this.stads = result;
        this.updatedStads.next(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getVideos(id) {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/match-videos/${id}`)
      .subscribe((result) => {
        this.documents = result;
        this.updatedDocuments.next(result);
      });
  }
  deleteVideos(id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/deleteMatchVideo/${id}`
    );
  }
  addVideos(info, id, file) {
    let data = new FormData();
    data.append("title", info.title);
    data.append("description", info.description);
    data.append("date", info.date);
    data.append("file", file);
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/addMatchVideo/${id}`,
      data
    );
  }
  updateVideos(info, id, file) {
    let data = new FormData();
    data.append("title", info.title);
    data.append("path", info.path);
    data.append("description", info.description);
    data.append("date", info.date);
    data.append("file", file);
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/editMatchVideo/${id}`,
      data
    );
  }
  getImages(id) {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/match-images/${id}`)
      .subscribe((result) => {
        this.documents = result;
        this.updatedDocuments.next(result);
      });
  }
  deleteImages(id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/deleteMatchImage/${id}`
    );
  }
  addImages(info, id, file) {
    let data = new FormData();
    data.append("title", info.title);
    data.append("description", info.description);
    const date = new Date();
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const year = date.getFullYear();
    data.append("date", year + "-" + month + "-" + day);
    file.forEach((item, index) => {
      if (index <= 10) {
        data.append("file" + index, item);
      }
    });
    // return this.http.post(`${this.link}/${this.API}/${this.endpoint}/addMatchImage/`+id, data);
    const req = new HttpRequest(
      "POST",
      `${this.link}/${this.API}/${this.endpoint}/addMatchImage/${id}`,
      data,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }
  updateImages(info, id, file) {
    let data = new FormData();
    data.append("title", info.title);
    data.append("path", info.path);
    data.append("description", info.description);
    const date = new Date();
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const year = date.getFullYear();
    data.append("date", year + "-" + month + "-" + day);
    file.forEach((item, index) => {
      if (index <= 10) {
        data.append("file" + index, item);
      }
    });
    const req = new HttpRequest(
      "POST",
      `${this.link}/${this.API}/${this.endpoint}/editMatchImage/${id}`,
      data,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }
  getButMarqueRatio() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/butMarqueRatio`
    );
  }
  getButRecuRatio() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/butRecuRatio`
    );
  }
  getMatchJoue() {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/matchJoue`);
  }
  resultsPerMonth() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/resultsPerMonth`
    );
  }
  butsPerMonth() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/butsPerMonth`
    );
  }
  getMatchJoueResults() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/matchJoueResults`)
      .subscribe((result) => {
        this.updatedMatchJoueResults.next(result);
      });
  }
  getMatchJoueResultsCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/matchJoueResultsCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedMatchJoueResults.next(result);
      });
  }
  deleteAction(table, id) {
    return this.http.delete(
      `${this.link}/${this.API}/${this.endpoint}/deleteAction/${table}/${id}`
    );
  }
  updateAction(infos, type, id) {
    const link = `${this.link}/${this.API}/${this.endpoint}/updateAction/${type}/${id}`;
    return this.http.post(link, infos);
  }
  addMatch(infos, path = "/match/calendar") {
    this.http
      .post(`${this.link}/${this.API}/${this.endpoint}/add`, infos)
      .subscribe(
        (result) => {
          if (result) {
            this.router.navigate([path]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  saveMatch(infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/add`,
      infos
    );
  }
  addCalendarMatch(infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/add`,
      infos
    );
  }
  addAnalyseAdversaire(infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/addAnalyseAdversaire`,
      infos
    );
  }
  getAnalyseAdversaire(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/analyseAdversaire/${id}`
    );
  }
  updateMatchCalendar(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/update/${id}`,
      infos
    );
  }
  getPcma(id) {
    return this.http.get(`${this.link}/${this.API}/joueur/pcma/${id}`);
  }
  updatePcma(id, infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/update_pcma/${id}`,
      infos
    );
  }
  insertPcma(id, infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/pcma/${id}`,
      infos
    );
  }
  updateMatch(infos, id) {
    this.http
      .post(`${this.link}/${this.API}/${this.endpoint}/update/${id}`, infos)
      .subscribe(
        (result) => {
          if (result) {
            this.router.navigate([`/match/matchActions/matchFile/${id}`]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  savePlayersPositions(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/savePositions_new/${id}`,
      infos
    );
  }
  saveReplacementList(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/addReplacementList/${id}`,
      infos
    );
  }
  getRpeBeforeMatch(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/rpeBeforeMatch/${id}`
    );
  }
  saveRpeBeforeMatch(matchId, playerId, data) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/updateRpeBeforeMatch/${matchId}/${playerId}`,
      data
    );
  }
  getRpeAfterMatch(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/rpeAfterMatch/${id}`
    );
  }
  getEquipes() {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/equipes`);
  }
  getEquipesAll() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/equipes-all`
    );
  }
  saveRpeAfterMatch(matchId, playerId, data) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/updateRpeAfterMatch/${matchId}/${playerId}`,
      data
    );
  }
  updateMinutes(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/updateMinutes/${id}`,
      infos
    );
  }
  addPlayerMatchComment(comment, matchId, playerId) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/addPlayerMatchComment/${matchId}/${playerId}`,
      { comment: comment }
    );
  }
  updateNotes(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/updateNotes/${id}`,
      infos
    );
  }
  saveMatchData(infos, id, audio) {
    let data = new FormData();
    data.append("voice_note", audio);
    data.append("arbitre", infos.arbitre);
    data.append("conditions_meteo", infos.conditions_meteo);
    data.append("adver_systeme_jeu", infos.adver_systeme_jeu);
    data.append("adver_point_faible", infos.adver_point_faible);
    data.append("adver_point_fort", infos.adver_point_fort);
    data.append("note_match", infos.note_match);
    data.append("terrain", infos.terrain);
    data.append("consignes", infos.consignes);
    data.append("audio", infos.audio);
    data.append("dure", infos.dure);
    data.append("old_dure", infos.old_dure);
    data.append("adver_nbr_jeu", infos.adver_nbr_jeu);
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveMatchDataNew/${id}`,
      data
    );
  }
  strikerTime() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/strikerTime`)
      .subscribe((result: any) => {
        this.strikerTimeButs = result;
        this.updatedStrikerTimeButs.next(result);
      });
  }
  receiverTime() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/receiverTime`)
      .subscribe((result: any) => {
        this.receiverTimeButs = result;
        this.updatedReceiverTimeButs.next(result);
      });
  }
  strikerTimeCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/strikerTimeCompetition/` +
          competition
      )
      .subscribe((result: any) => {
        this.strikerTimeButs = result;
        this.updatedStrikerTimeButs.next(result);
      });
  }
  receiverTimeCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/receiverTimeCompetition/` +
          competition
      )
      .subscribe((result: any) => {
        this.receiverTimeButs = result;
        this.updatedReceiverTimeButs.next(result);
      });
  }
  strikerTimeFutsal() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/strikerTimeFutsal`)
      .subscribe((result: any) => {
        this.strikerTimeButs = result;
        this.updatedStrikerTimeButs.next(result);
      });
  }
  receiverTimeFutsal() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/receiverTimeFutsal`)
      .subscribe((result: any) => {
        this.receiverTimeButs = result;
        this.updatedReceiverTimeButs.next(result);
      });
  }
  strikerTimeCompetitionFutsal(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/strikerTimeCompetitionFutsal/` +
          competition
      )
      .subscribe((result: any) => {
        this.strikerTimeButs = result;
        this.updatedStrikerTimeButs.next(result);
      });
  }
  receiverTimeCompetitionFutsal(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/receiverTimeCompetitionFutsal/` +
          competition
      )
      .subscribe((result: any) => {
        this.receiverTimeButs = result;
        this.updatedReceiverTimeButs.next(result);
      });
  }
  butMarqueZone() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butMarqueZone`)
      .subscribe((result) => {
        this.updatedButMarqueZone.next(result);
      });
  }
  cartonJauneZone() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/cartonJauneZone`)
      .subscribe((result) => {
        this.updatedCartonJauneZone.next(result);
      });
  }
  cartonRougeZone() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/cartonRougeZone`)
      .subscribe((result) => {
        this.updatedCartonRougeZone.next(result);
      });
  }
  butRecuZone() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butRecuZone`)
      .subscribe((result) => {
        this.updatedButRecuZone.next(result);
      });
  }
  butMarqueBody() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butMarqueBody`)
      .subscribe((result) => {
        this.updatedButMarqueBody.next(result);
      });
  }
  butRecuBody() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butRecuBody`)
      .subscribe((result) => {
        this.updatedButRecuBody.next(result);
      });
  }
  butMarqueType() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butMarqueType`)
      .subscribe((result) => {
        this.updatedButMarqueType.next(result);
      });
  }
  butRecuType() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butRecuType`)
      .subscribe((result) => {
        this.updatedButRecuType.next(result);
      });
  }

  butMarqueZoneCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butMarqueZoneCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButMarqueZone.next(result);
      });
  }
  butRecuZoneCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butRecuZoneCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButRecuZone.next(result);
      });
  }
  butMarqueBodyCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butMarqueBodyCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButMarqueBody.next(result);
      });
  }
  butRecuBodyCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butRecuBodyCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButRecuBody.next(result);
      });
  }
  butMarqueTypeCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butMarqueTypeCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButMarqueType.next(result);
      });
  }
  butRecuTypeCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butRecuTypeCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButRecuType.next(result);
      });
  }
  butSystem() {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/butSystem`);
  }
  butAddverSystem() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/butAddverSystem`
    );
  }
  butBothSystem() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butBothSystem`)
      .subscribe((result: any) => {
        this.updatedButBothSystem.next(result);
      });
  }
  butBothSystemCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butBothSystemCompetition/` +
          competition
      )
      .subscribe((result: any) => {
        this.updatedButBothSystem.next(result);
      });
  }
  butByTerrain() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butByTerrain`)
      .subscribe((result) => {
        this.updatedButByTerrain.next(result);
      });
  }
  butByConditionMeteo() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/butByConditionMeteo`)
      .subscribe((result) => {
        this.updatedButByConditionMeteo.next(result);
      });
  }
  butByTerrainCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butByTerrainCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButByTerrain.next(result);
      });
  }
  butByConditionMeteoCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/butByConditionMeteoCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedButByConditionMeteo.next(result);
      });
  }
  matchAvertissement() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/matchAvertissement`)
      .subscribe((result) => {
        this.updatedMatchAvertissement.next(result);
      });
  }
  matchAvertissementCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/matchAvertissementCompetition/` +
          competition
      )
      .subscribe(
        (result) => {
          this.updatedMatchAvertissement.next(result);
        },
        (error) => {
          return [];
        }
      );
  }
  avertissementMatch(competition) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/avertissementMatch/` +
        competition
    );
  }
  avertissementMatchAll() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/avertissementMatchAll`
    );
  }
  playersPasses() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/playersPasses`)
      .subscribe((result) => {
        this.updatedPlayersPasses.next(result);
      });
  }
  playersPassesCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/playersPassesCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedPlayersPasses.next(result);
      });
  }
  changePrime(id, newPrime) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/changeprime/${id}`,
      { newPrime }
    );
  }
  // playersTiming() {
  //     this.http.get(`${this.link}/${this.API}/${this.endpoint}/playersTiming`).subscribe(
  //         result => {
  //             this.updatedPlayersTiming.next(result);
  //         }
  //     );
  // }
  // playersTimingCompetition(competition) {
  //     this.http.get(`${this.link}/${this.API}/${this.endpoint}/playersTimingCompetition/` + competition).subscribe(
  //         result => {
  //             this.updatedPlayersTiming.next(result);
  //         }
  //     );
  // }
  // playersTimingCompetitionAll(data) {
  //     return this.http.post(`${this.link}/${this.API}/${this.endpoint}/playersCompetitionTiming/all`, { competition_ids: JSON.stringify(data) });
  // }
  matchsTiming() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/matchsTiming`)
      .subscribe((result) => {
        this.updatedMatchsTiming.next(result);
      });
  }
  matchsTimingCompetition(competition) {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/matchsTimingCompetition/` +
          competition
      )
      .subscribe((result) => {
        this.updatedMatchsTiming.next(result);
      });
  }
  matchsTimingCompetitionAll() {
    this.http
      .get(
        `${this.link}/${this.API}/${this.endpoint}/matchsTimingCompetition/all`
      )
      .subscribe((result) => {
        this.updatedMatchsTimingAll.next(result);
      });
  }
  saveButMarque(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveButMarque/${id}`,
      infos
    );
  }
  getButMarque(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/butMarque/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.butMarque = result;
      this.updatedButMarque.next(result);
    });
  }
  saveButRecu(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveButRecu/${id}`,
      infos
    );
  }
  getButRecu(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/butRecu/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.butRecu = result;
      this.updatedButRecu.next(result);
    });
  }
  saveCarton(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveCarton/${id}`,
      infos
    );
  }
  getCarton(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/cartons/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.cartons = result;
      this.updatedCartons.next(result);
    });
  }

  getAllPlayers(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/all-players/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.players = result;
      this.updatedAllPlayers.next(result);
    });
  }
  getPlayers(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/players/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.players = result;
      this.updatedPlayers.next(result);
    });
  }
  getMatchComments(id, playerid) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchs_comments/${id}/${playerid}`
    );
  }
  getMatchCommentsPlayers(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchs_comments_players/${id}`
    );
  }
  getPlayersWithInjuries(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/playersInjuries/${id}`;
    return this.http.get(url);
  }
  getListPlayersWithInjuries(id: number, stageId: number = null) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/listplayersInjuries/${id}${
        stageId != null ? `/${stageId}` : ""
      }`
    );
  }

  getPlayerChargeCA(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/joueurChargeCA/${id}`
    );
  }

  getPlayerDailyChargeW(id, filter = {}) {
    this.http
      .post(
        `${this.link}/${this.API}/${this.endpoint}/joueurDailyCharge/${id}`,
        filter
      )
      .subscribe((result: any) => {
        this.updatedPlayerDailyChargeW.next(result);
      });
  }

  getPlayerDailyCharge(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/joueurDailyCharge/${id}`
    );
  }
  latestMatchPlayers(competition, date) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/latestMatchPlayers/` +
        competition,
      { date: date }
    );
  }
  getAnalyseAdversaires() {
    this.http
      .get(`${this.link}/${this.API}/${this.endpoint}/analyseAdversaires`)
      .subscribe(
        (result) => {
          this.updatedAnalyseAdversaires.next(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }
  saveRemplacement(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveRemplacement/${id}`,
      infos
    );
  }
  getRemplacement(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/remplacements/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.remplacements = result;
      this.updatedRemplacements.next(result);
    });
  }
  saveArrets(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveArrets/${id}`,
      infos
    );
  }
  getArrets(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/arrets/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.arrets = result;
      this.updatedArrets.next(result);
    });
  }
  saveFaits(infos, id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/saveFaits/${id}`,
      infos
    );
  }
  getFaits(id) {
    let url = `${this.link}/${this.API}/${this.endpoint}/faits/${id}`;
    this.http.get(url).subscribe((result: any) => {
      this.faits = result;
      this.updatedFaits.next(result);
    });
  }
  getComposition(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/composition/${id}`
    );
  }
  getHotel(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/hotels/${id}`
    );
  }
  getFlights(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/flights/${id}`
    );
  }
  getPlayersPositions(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/playersPositions/${id}`
    );
  }
  getReplacementsList(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/replacementsList/${id}`
    );
  }
  getFullReplacementsList(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/fullReplacementsList/${id}`
    );
  }
  getMatch(id) {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/one/${id}`);
  }
  getallMatchs() {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/all`);
  }
  getLatest() {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/latest`);
  }
  getComming() {
    return this.http.get(`${this.link}/${this.API}/${this.endpoint}/comming`);
  }
  getMonthMatchs(month) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/month/` + month
    );
  }
  singleMatch(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/singleMatch/${id}`
    );
  }
  matchReplacements(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchReplacements/${id}`
    );
  }
  matchPlayers(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchPlayers/${id}`
    );
  }
  matchPlayersIsAndWasIn(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchPlayersIsAndWasIn/${id}`
    );
  }
  matchPlayersIsAndWasInPrime(id, localisation) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/_matchPlayersIsAndWasIn/${id}/${localisation}`
    );
  }
  matchEntringPlayers(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchEntringPlayers/${id}`
    );
  }
  bestComposition() {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchsCompositionsPlayers`
    );
  }
  updateComposition(id, formation, nbr_players) {
    this.http
      .post(
        `${this.link}/${this.API}/${this.endpoint}/updateCompositionNew/${id}`,
        { formation: formation, nbr_players: nbr_players }
      )
      .subscribe(
        (result) => {
          return true;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  public ConvoquerJoueurs(
    clubIds: number[],
    date: string
  ): Observable<ConvoquerPlayer> {
    return this.http.post<ConvoquerPlayer>(
      `${this.link}/${this.API}/${this.endpoint}/convoquer-joueurs`,
      { clubIds: clubIds, date: date }
    );
  }
  getMatchChecklist(id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/checklist/${id}`
    );
  }
  updateMatchChecklist(id, infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/update_checklist/${id}`,
      infos
    );
  }
  insertMatchChecklist(id, infos) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/checklist/${id}`,
      infos
    );
  }
  // football api services
  getH2H(equipeId, opponentId) {
    return this.http.get(
      this.footballApiLink + `fixtures/h2h/${equipeId}/${opponentId}`,
      {
        headers: { "X-RapidAPI-Key": this.footballApiKey },
      }
    );
  }
  getPredictions(fixture_id) {
    return this.http.get(this.footballApiLink + "predictions/" + fixture_id, {
      headers: { "X-RapidAPI-Key": this.footballApiKey },
    });
  }
  getTeamData(team_id) {
    return this.http.get(this.footballApiLink + "teams/team/" + team_id, {
      headers: { "X-RapidAPI-Key": this.footballApiKey },
    });
  }
  getMatchStatistics(match_id) {
    return this.http.get(
      this.footballApiLink + "statistics/fixture/" + match_id,
      {
        headers: { "X-RapidAPI-Key": this.footballApiKey },
      }
    );
  }
  getSoccerWayData(team_link) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/teamDataSoccerWay`,
      { team_link: team_link }
    );
  }
  getSoccerWayStats(team_id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/soccerWayStats/` + team_id
    );
  }
  getSoccerWayMatchs(team_id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/soccerWayMatchs/` + team_id
    );
  }
  getSoccerWayPlayer(link) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/soccerWayPlayer`,
      { link: link }
    );
  }
  getSoccerWayPlayerCareer(player_id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/soccerWayPlayerCareer/` +
        player_id
    );
  }
  getSoccerWayPlayerMatchs(player_id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/soccerWayPlayerMatchs/` +
        player_id
    );
  }

  saveStatistique(Object, match_id) {
    return this.http.post(
      `${this.link}/${this.API}/${this.endpoint}/matchstatistics/` + match_id,
      { statistiques: Object }
    );
  }
  getStatistique(match_id) {
    return this.http.get(
      `${this.link}/${this.API}/${this.endpoint}/matchstatistics/` + match_id
    );
  }
  matchPlayerNum(
    matchId: number,
    playerId: number,
    num: number
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.link}/${this.API}/${this.endpoint}/player-num/${matchId}/${playerId}/${num}`
    );
  }
  getMatchPlayersNum(matchId: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.link}/${this.API}/${this.endpoint}/get-player-num/${matchId}`
    );
  }

  SearchByClubsKey(key: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.link}/${this.API}/stage/clubs/${key}`);
  }
}
