import { Component, OnInit } from '@angular/core';
import { ngRoutes } from './routes.sideblocks'
import { authService } from 'src/app/components/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sideblocks',
  templateUrl: './sideblocks.component.html',
  styleUrls: ['./sideblocks.component.css']
})
export class SideblocksComponent implements OnInit {
  ROUTES_: any[] = []
  ROUTES: any[] = []
  key: string = null;
  envirement: string;

  constructor(private authService: authService, private router: Router) {
    this.ROUTES_ = ngRoutes()
    this.ROUTES = ngRoutes().map(e => {
      delete e.children;
      return e;
    });
    this.envirement = environment.type;
  }

  ngOnInit() {
  }
  actions(CASE: string, RES = null): void {
    switch (CASE) {
      case 'NAVIGATE_TO':
        if (RES.route.length === 0) {
          if (RES.key) {
            this.key = RES.key;
            this.ROUTES = this.ROUTES_.find(route => route?.key === RES.key)?.children;
          } else {
            this.key = null;
            this.ROUTES = ngRoutes().map(e => {
              delete e.children;
              return e;
            });
          }
        } else {
          this.router.navigate(RES?.route);
        }
        break;
      case 'SIGN_OUT':
        this.authService.logout()
        break;
      default:
        break;
    }
  }
}
